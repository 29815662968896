import React from 'react';
import { graphql } from "gatsby";
import Layout from "../page-components/layout/layout";
import GsHeader from "../page-components/header/gsHeader";
import LogoProductConfigurator from "./logo-product-configuratior";
import GsButton from "../page-components/buttons/gsButton";
import LogoballVideo from "../images/logoball.mp4";

const LogoProductTemplate = ( { data } ) => {

    const products = data.shopifyCollection.products;
    const isLogoBall = data.shopifyCollection.handle === 'logo-golfballe';
    const collectionTitle = data.shopifyCollection.title;
    const showVideo = collectionTitle.toLowerCase().includes('golfbälle');

    return <Layout>
        <div className="container bg-white h-100 pb-5">
            <div className="row mb-5">
                <GsHeader>
                    <h1>Ihre individuellen { collectionTitle }</h1>
                    <p>Ihr individuelles Firmen-Logo etc. direkt auf Ihre { data.shopifyCollection.title} gedruckt.</p>

                    {showVideo && 
                      <video preload="metadata" loop muted autoPlay playsInline className="tw-max-h-[400px] tw-object-cover tw-w-full tw-mt-3 tw-rounded" >
                        <source src={LogoballVideo} type="video/mp4"/>
                      </video>
                    }

                </GsHeader>

                {isLogoBall && data.strapiPageConfig.logopreisliste?.publicURL &&
                    <div className={"mt-0 tw-py-8 tw-bg-green-900"}>

                        <a href={data.strapiPageConfig.logopreisliste.publicURL} target={"_blank"}>
                            <GsButton variant={"accent"} className={"tw-ml-5 tw-uppercase"}>
                                Aktuelle Preisliste
                            </GsButton>
                        </a>

                        <p className="tw-text-white tw-text-sm tw-font-light tw-mt-4 tw-mb-0 tw-ml-5 tw-mr-5">
                            Die Preisliste hat die Preise auch ohne Umsatzsteuer.<br />
                            Und definiert die Anforderungen an die Logo-Datei nochmals genauer.
                        </p>

                    </div>
                }

               

            </div>

            <LogoProductConfigurator collectionTitle={collectionTitle}
                                     isLogoBall={isLogoBall}
                                     products={products} />
        </div>
    </Layout>


};

export default LogoProductTemplate;

export const query = graphql`
    query logoProductCategoryQuery($id: String!) {
         strapiPageConfig {
            logopreisliste {
                publicURL
            }
        }
            
        shopifyCollection(id: {eq: $id}) {
            id
            title
            handle
            descriptionHtml
            products {
              images {
                originalSrc
              }
              handle
              priceRangeV2 {
                minVariantPrice {
                  amount
                }
                maxVariantPrice {
                  amount
                }
              }
              productType
              shopifyId
              title
              vendor
              tags
              descriptionHtml
              metafields {
                namespace
                key
                value
              }
              variants {
                id
                shopifyId
                availableForSale
                title
                compareAtPrice
                selectedOptions {
                  value
                  name
                }
                price
              }
              options {
                name
                values
              }
            }
      }
    }`;
